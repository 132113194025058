const EN = {
  loading: 'Loading...',
  error: 'Error',
  welcomeBack: 'Welcome back',
  signInMsg: 'Sign-in with the data that you entered during your registration',
  email: 'Email',
  emailAddress: 'Email address',
  password: 'Password',
  forgotPwd: 'Forgot Password?',
  signIn: 'Sign in',
  fieldRequired: '{{field}} is required.',
  fieldInvalid: '{{field}} is invalid.',
  verifyYou: 'Verify it’s you',
  verifyCodeMsg: 'Please enter the 6 digit code we sent to ',
  resetPwd: 'Reset password',
  resetPwdMsg: 'Enter your email address and we’ll send you instructions to reset your password.',
  newPwd: 'New Password',
  updatePwd: 'Update Password',
  pwdMinLength: 'At least 10 characters',
  pwdLowerCase: 'A lower case letter',
  pwdUpperCase: 'An upper case letter',
  pwdNumber: 'Contains a number',
  pwdSpecialChar: 'A special character',
  save: 'Save',
  badRequest: 'Invalid request.',
  unauthorized: 'Unauthorized access.',
  forbidden: 'Access forbidden.',
  notFound: 'The requested resource could not be found.',
  internalServerError: 'An unexpected error occurred on the server. Please try again later.',
  notImplemented: 'The server does not support the request.',
  serviceUnavailable: 'The server is currently unable to handle the request.',
  abortError: 'The request was aborted. Please try again.',
  typeError: 'A type error occurred. Please check your request data and try again.',
  syntaxError: 'A syntax error occurred. Please check your request data and try again.',
  networkError: 'A network error occurred. Please check your internet connection and try again.',
  unknownError: 'An error occurred:',
  defaultErrorMsg: 'Failed to process you request. Please try again later.',
  sessionExpiredMsg: 'The session has expired. Please try again',
  sessionTimeout: 'Session Timeout',
  sessionTimeoutMsg:
    'For your security, we log you out automatically when you have not been active for a certain period of time.',
  continueSession: 'Continue Session',
  cancel: 'Cancel',
  signOut: 'Sign-out',
  signUp: 'Sign up',
  version: 'Version',
  welcome1Title: 'Safe & secure',
  welcome1Desc: 'Communicate with confidence, knowing that your messages are stored securely.',
  welcome2Title: 'You’re in control',
  welcome2Desc: 'Control and manage your data in the cloud.',
  welcome3Title: 'Seamless',
  welcome3Desc: 'Send chats, documents, images, video and voice recordings instantly.',
  signInDesc: 'Use your email address to sign-in to your account.',
  signInFtrCaption: 'Don’t yet have an account?',
  continue: 'Continue',
  enterPassword: 'Enter Password',
  signInPwdDesc: 'Please enter the password to access your account.',
  signInPwdFtrCaption: 'Having problems signing in?',
  emailSupport: 'Email support.',
  signInVerifyFtrCaption: 'Didn’t get it?',
  resend: 'Resend.',
  yourEmail: 'your email',
  resendEmailTitle: 'We need to verify it’s you',
  resendEmailMsg1: 'An email has been sent to',
  resendEmailMsg2: 'with a link to verify your account.',
  resendEmail: 'Resend email',
  contactSupport: 'Contact support',
  dontAskAgain: 'Don’t show again',
  checkSpamMsg: 'If you have not received an email after a few minutes, please check your spam folder.',
  signInOptionTitle: 'Sign in with:',
  signInOptionMsg: 'Choose an option to sign-in with:',
  emailLink: 'Email link',
  verificationResent: 'Verification code was resent successfully.',
  admin: 'Admin',
  users: 'Users',
  departments: 'Departments',
  settings: 'Settings',
  domains: 'Domains',
  permissions: 'Permissions',
  sites: 'Sites',
  all: 'All',
  priority: 'Priority',
  unread: 'Unread',
  starred: 'Starred',
  messageTxt: 'Message',
  inbox: 'Inbox',
  messagingAs: 'Messaging as',
  you: 'You',
  createdGroup: 'created this group.',
  yesterday: 'Yesterday',
  verifyLinkExpired: 'Verification link has expired',
  verifyLinkExpiredMsg: 'Looks like the verification link has expired. Not to worry, we can send the link again.',
  resendLink: 'Resend verification link',
  stillProblem: 'Still having problems?',
  today: 'Today',
  urgent: 'Urgent',
  normal: 'Normal',
  camera: 'Camera',
  photo: 'Photo',
  video: 'Video',
  document: 'Document',
  loadingHistory: 'Loading history...',
  mute: 'Mute',
  createPassword: 'Create password',
  next: 'Next',
  addNewDept: 'Add a new department',
  findDepartments: 'Find departments',
  addDepartment: 'Add department',
  noOptions: 'No options',
  noDepts: 'No departments',
  members: 'Members',
  search: 'Search',
  manageSites: 'Manage your sites.',
  newDepartment: 'New department',
  name: 'Name',
  description: 'Description',
  optional: '(optional)',
  deptDescPlaceholder: 'Add a description here. This helps colleagues know they’re in the right place.',
  findSites: 'Find sites',
  noSites: 'No sites',
  charCountExceed: '{{field}} cannot be more than {{count}} characters.',
  department: 'Department',
  firstName: 'First name',
  lastName: 'Last name',
  position: 'Position',
  aboutYou: 'About you',
  aboutYouPlaceholder: 'You can write a brief bio to help others discover you, or fill this information out later.',
  createProfile: 'Create profile',
  noDataFound: 'No data found',
  noMessages: 'You have no messages',
  noSitesFound: 'No sites found',
  invalidField: '{{field}} is invalid.',
  numberNotAllowed: 'Numbers are not allowed.',
  telephoneNumber: 'Telephone number',
  address: 'Address',
  newSite: 'New site',
  editSite: 'Edit site',
  delete: 'Delete',
  deleteSite: 'Delete site',
  deleteSiteMsg:
    'Are you sure you want to permanently delete this site? Once deleted the data will be lost and cannot be recovered.',
  leaveWithouSave: 'Leave without saving',
  leaveWithouSaveMsg: 'Any changes that you have made will not be saved.',
  leave: 'Leave',
  setNewPwd: 'Set new password',
  directory: 'Directory',
  filters: 'Filters',
  sortNFilters: 'Sort & filter',
  submit: 'Submit',
  sortBy: 'Sort by',
  site: 'Site',
  type: 'Type',
  keywords: 'Keywords',
  groups: 'Groups',
  resetAll: 'Reset all',
  applyFilters: 'Apply filters',
  created: 'Created',
  requestToJoin: 'Request to join',
  createGroup: 'Create group',
  newGroup: 'New group',
  createGroupDesc: 'Add a description here. This helps colleagues know they’re in the right place.',
  membersRequired: 'Add at least one member to this group.',
  addMembers: 'Add members',
  sending: 'Sending...',
  apply: 'Apply',
  leaveGroup: 'Leave group',
  editGroup: 'Edit group',
  details: 'Details',
  admins: 'Admins',
  removeGrpUser: 'Remove user from group',
  removeGrpUserMsg: 'Are you sure you want to remove this user from the group?',
  ok: 'OK',
  makeAdmin: 'Make admin',
  dismissAdmin: 'Dismiss admin',
  aboutMe: 'About me',
  lock: 'Lock',
  unlock: 'Unlock',
  deactivate: 'Deactivate',
  deactivateUser: 'Deactivate user',
  deactivateUserMsg:
    'Are you sure you want to deactivate this user? This user will no longer have access to their account.\nAll data associated with this account will be kept in case you want to reactivate it.',
  editUser: 'Edit user',
  profile: 'Profile',
  lockAccount: 'Lock account',
  deleteAccount: 'Delete account',
  reactivateAccount: 'Reactivate account',
  deactivateAccount: 'Deactivate account',
  deleteUser: 'Delete user',
  deleteUserMsg:
    'Are you sure you want to permanently delete this user? Once deleted the data will be lost and cannot be recovered.',
  userType: 'User type',
  information: 'Information',
  forward: 'Forward',
  reply: 'Reply',
  forwardTo: 'Forward to',
  send: 'Send',
  sent: 'Sent',
  forwarded: 'Forwarded',
  manageDepartments: 'Manage departments',
  noDepartmentsFound: 'No departments found',
  new: 'New',
  edit: 'Edit',
  deleteDept: 'Delete department',
  deleteDeptMsg:
    'Are you sure you want to permanently delete this department? Once deleted the data will be lost and cannot be recovered.',
  cantDeleteDept: 'Can’t delete department',
  cantDeleteDeptMsg:
    'This department can’t be deleted because it still has members. Please remove these members and try again.',
  editDepartment: 'Edit department',
  existingMembers: 'Existing members',
  removeDeptUser: 'Remove user from department',
  removeDeptUserMsg: 'Are you sure you want to remove this user from the department?',
  manageUsers: 'Manage users',
  active: 'Active',
  pending: 'Pending',
  deactivated: 'Deactivated',
  invite: 'Invite',
  select: 'Select',
  noPositions: 'No positions',
  noUserTypes: 'No user types',
  user: 'User',
  invited: 'Invited',
  resendInvite: 'Resend invite',
  reactivate: 'Reactivate',
  noUsersFound: 'No users found',
  youAddedToGroup: 'You were added to this group.',
  userProfile: 'User profile',
  userProfileSubtitle: 'Manage your details and view your status',
  available: 'Available',
  away: 'Away',
  doNotDisturb: 'Do not disturb',
  status: 'Status',
  editProfile: 'Edit profile',
  appearance: 'Appearance',
  security: 'Security',
  notifications: 'Notifications',
  language: 'Language',
  help: 'Help',
  system: 'System',
  light: 'Light',
  dark: 'Dark',
  changePassword: 'Change password',
  allowNotifications: 'Allow notifications',
  privacyPolicy: 'Privacy policy',
  tandC: 'Terms and conditions',
  deactivateMyAccount: 'Deactivate my account',
  inviteCollegues: 'Invite collegues',
  choosePreset: 'Choose preset',
  emails: 'Emails',
  invitesSent: 'Invites sent',
  invitesSentMsg:
    'Your colleagues will receive an email with an activation link inviting them to join your network. You can check the status of the invitation in the “Users” page.',
  userTypeTooltip: 'These settings will be applied to all colleagues you add with this invite.',
  messageInfo: 'Message information',
  read: 'Read',
  delivered: 'Delivered',
  domain: 'Domain',
  domainMsg: 'Manage the approved domains for your system.',
  approvedEmailDomains: 'Approved email domains',
  add: 'Add',
  domainOutsideWarning: 'from outside your organisation',
  sentBy: 'Sent by',
  on: 'on',
  sentAttachment: 'Sent an attachment.',
	fileSizeExceed: 'The file size must not exceed 10MB',
	cameraNotAvailable: 'Camera is not available',
  devices: 'Devices',
  logs: 'Logs',
  call: 'Call',
  manageYourDevices: 'Manage your devices',
  identifier: 'Identifier',
  deviceType: 'Device type',
  assignedTo: 'Assigned to',
  noDevicesFound: 'No devices found',
  newDevice: 'New device',
  ricNumber: 'RIC number',
  assignTo: 'Assign to',
  noPagerTypes: 'No pager types',
  reassign: 'Reassign',
  assign: 'Assign',
  editDevice: 'Edit device',
  digitValidation: 'Must be exactly {{value}} digits',
  reassignTo: 'Reassign to',
  micNotAvailable: 'Microphone not available',
  pager: 'Pager',
  micAccessDeniedTitle: 'Microphone access denied',
  micAccessDeniedMsg: 'To record voice messages, please enable microphone access in your browser settings.',
  close: 'Close',
  message: 'message',
  device: 'Device',
  unassignDevice: 'Unassign device',
  unassignDeviceMsg: 'Are you sure you want to unassign this device?'
};

export default EN
