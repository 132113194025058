import { AppFeature, DEFAULT_PAGE_SIZE, DIRECTORY_CARD_MAX_WIDTH, DirectoryRecordType, SortByAlphabet, SortOrder } from '../../utils/constants';
import { Box, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import CircularProgressBar from '../../components/CircularProgressBar';
import CreateGroupCard from './CreateGroupCard';
import CreateGroupDialog from './CreateGroupDialog';
import CustomButton from '../../components/CustomButton';
import { DIRECTORY_TYPE_LIST } from '../../utils/ui-constants';
import DirectoryCard from './DirectoryCard';
import DirectoryFilter from '../../types/ui/directory-filter';
import { DirectoryRecord } from '../../types/directory-record';
import { DirectorySearchRequest } from '../../types/directory-search-request';
import DirectorySortFilter from './DirectorySortFilter';
import DirectorySortFilterDialog from './DirectorySortFilterDialog';
import FilterIcon from '../../components/CustomIcons/FilterIcon';
import { Group } from '../../types/group';
import GroupViewPanel from './GroupViewPanel';
import LoginUtil from '../../utils/login-util';
import { Masonry } from '@mui/lab';
import { SEARCH_DIRECTORY } from '../../data/directory/action-types';
import SearchBar from '../../components/SearchBar';
import SearchIcon from '../../components/CustomIcons/SearchIcon';
import TopBar from '../../components/TopBar';
import { User } from '../../types/user';
import UserViewPanel from '../UserScreen/UserViewPanel';
import Util from '../../utils/util';
import { useContainerSize } from '../../hooks/use-container-size';
import { useDepartmentApi } from '../../data/department/api';
import { useDirectoryApi } from '../../data/directory/api';
import { useLoadMore } from '../../hooks/use-load-more';
import { useProfileApi } from '../../data/profile/api';
import { useSiteApi } from '../../data/site/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * DirectoryScreen component for managing directory data.
 *
 * This component displays a search bar, sort and filter options, and a grid of directory entries.
 *
 * @returns {JSX.Element} The rendered DirectoryScreen component.
 */
const DirectoryScreen: React.FC = () => {

  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isLoadMore = useLoadMore(containerRef);
  const [width] = useContainerSize(containerRef);
  const numOfColumns = Math.floor(width / DIRECTORY_CARD_MAX_WIDTH);
  const [searchKey, setSearchKey] = useState<string>('');
  const [openSortFilterDialog, setOpenSortFilterDialog] = useState<boolean>(false);
  const [openCreateGroupDialog, setOpenCreateGroupDialog] = useState<boolean>(false);
  const initialFilter: DirectoryFilter = {
    searchKey: '',
    sites: [],
    departments: [],
    sortBy: SortByAlphabet.A_Z,
    types: DIRECTORY_TYPE_LIST
  };
  const [filter, setFilter] = useState<DirectoryFilter>(initialFilter);
  const [directorySearchRequest, setDirectorySearchRequest] = useState<DirectorySearchRequest>();
  const [selectedRecord, setSelectedRecord] = useState<DirectoryRecord>();
  const [openGroupPanel, setOpenGroupPanel] = useState<boolean>(false);
  const [openUserPanel, setOpenUserPanel] = useState<boolean>(false);
  const [loggedInUserInfo, setLoggedInUserInfo] = useState<User>();
  const siteApi = useSiteApi();
  const deptApi = useDepartmentApi();
  const profileApi = useProfileApi();
  const directoryApi = useDirectoryApi();
  const apiStatus = directoryApi.state.directory.apiStatus;

  /**
   * Fetches initial data for sites and departments on component mount.
   * 
   * This effect triggers the `callInitialApis` function once when the component mounts,
   * ensuring that the initial data is fetched before rendering the component.
   */
  useEffect(() => {
    callInitialApis();
  }, []);

  /**
   * Handles infinite scrolling for directory search results.
   *
   * This effect triggers when `isLoadMore` changes. It checks if:
   *  - Load more is enabled (`isLoadMore` is true)
   *  - Directory search API is not currently loading (`!Util.isApiLoading(SEARCH_DIRECTORY, apiStatus)`)
   *  - A search request object exists (`directorySearchRequest`)
   *  - The pagination is not yet complete (`!directorySearchRequest.isComplete`)
   *
   * If all conditions are met, it increments the page number in the search request
   * and triggers a new search using `initSearchDirectoryApi`.
   */
  useEffect(() => {
    if (isLoadMore && !Util.isApiLoading([SEARCH_DIRECTORY], apiStatus)
      && directorySearchRequest && !directorySearchRequest.isComplete) {
      directorySearchRequest.page.pageNumber += 1;
      initSearchDirectoryApi(directorySearchRequest);
    }
  }, [isLoadMore]);

  /**
   * Fetches initial data for sites and departments.
   * 
   * This function triggers the `fetchSiteList` and `fetchDepartmentList` methods
   * from the respective API hooks (`useSiteApi` and `useDepartmentApi`) to retrieve
   * initial data for sites and departments. It also calls `initSearchDirectoryApi` 
   * to initiate the search for directory entries based on the initial filter.
   */
  const callInitialApis = () => {
    // TODO: Remove the profile API call once the pager information is not required for sending message.
    profileApi.fetchUserDetailsByEmailId(LoginUtil.getLoginId()).then(user => {
      if (user) {
        setLoggedInUserInfo(user);
      }
    });
    siteApi.fetchSiteList();
    deptApi.fetchDepartmentList();
    initSearchDirectoryRequest(initialFilter);
  }

  /**
   * Handles directory item selection.
   *
   * @param record - The selected directory record.
   */
  const onItemSelected = (record: DirectoryRecord) => {
    setSelectedRecord(record);
    if (record.groupRoomKey) {
      setOpenGroupPanel(true);
    } else if (record.email) {
      setOpenUserPanel(true);
    }
  }

  /**
   * Handles closing the group panel and clearing selected record.
   */
  const handleGroupPanelClose = () => {
    setOpenGroupPanel(false);
    setSelectedRecord(undefined);
  }

  /**
   * Handles closing the user panel and clearing selected record.
   */
  const handleUserPanelClose = () => {
    setOpenUserPanel(false);
    setSelectedRecord(undefined);
  }

  /**
   * Handles search form submission.
   *
   * @param {React.FormEvent<HTMLFormElement>} event - The form submit event.
   */
  const onSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onApplyFilter({ ...filter });
  }

  /**
   * Handles applying filter selections and initiating directory search if required.
   *
   * @param value - The updated filter object containing selected criteria.
   */
  const onApplyFilter = (value: DirectoryFilter) => {
    setOpenSortFilterDialog(false);
    value.searchKey = searchKey;
    if (JSON.stringify(filter) !== JSON.stringify(value)) { //Avoiding unwanted API calls.
      initSearchDirectoryRequest(value);
    }
    setFilter(value);
  }

  /**
   * Initializes a directory search request object based on the provided filter criteria.
   *
   * This function constructs a `DirectorySearchRequest` object with the following properties:
   *  - `search`: The search keyword from the filter (value.searchKey)
   *  - `types`: An array of selected directory type IDs (filtered and mapped from value.types based on `menu.isChecked`)
   *  - `filter`: An object with optional department and site IDs if any are selected (filtered based on emptiness using `Util.isArrayEmpty`)
   *      - `departmentIds`: Array of selected department IDs (from value.departments.map(department => department.id))
   *      - `siteIds`: Array of selected site IDs (from value.sites.map(site => site.id))
   *  - `page`: An object defining pagination details
   *      - `pageNumber`: Starts at 0 for initial search
   *      - `size`: Set to `DEFAULT_PAGE_SIZE` (assumed constant)
   *      - `sort`: An object defining the sorting criteria
   *          - `sortBy`: Set to `DirectoryRecordType.User` (assumed default)
   *          - `order`: Determined by the selected sort order (ascending for A-Z, descending otherwise) based on `value.sortBy`
   *  - `isComplete`: Set to `false` to indicate an ongoing search
   *
   * The constructed request is then passed to `initSearchDirectoryApi` for further processing.
   *
   * @param {DirectoryFilter} value - The filter object containing search and selection criteria.
   */
  const initSearchDirectoryRequest = (value: DirectoryFilter) => {
    // TODO: When empty array issue is fixed at backend, remove passing null.
    const types: Array<string> = value.types.filter(menu => menu.isChecked).map(menu => menu.id as string);
    const departmentIds: Array<string> = value.departments.map(department => department.id);
    const siteIds: Array<string> = value.sites.map(site => site.id);
    const request: DirectorySearchRequest = {
      search: value.searchKey,
      types: Util.isArrayEmpty(types) ? null : types,
      filter: {
        departmentIds: Util.isArrayEmpty(departmentIds) ? null : departmentIds,
        siteIds: Util.isArrayEmpty(siteIds) ? null : siteIds
      },
      page: {
        pageNumber: 0,
        size: DEFAULT_PAGE_SIZE,
        sort: {
          sortBy: DirectoryRecordType.User,
          order: value.sortBy === SortByAlphabet.A_Z ? SortOrder.Asc : SortOrder.Desc
        }
      },
      isComplete: false
    };
    initSearchDirectoryApi(request);
  }

  /**
   * Initiates a directory search API call using the provided request object.
   *
   * This function dispatches a search request to the directory API and updates the
   * `directorySearchRequest` state with the provided request object. It also handles
   * cases where the API response indicates that there are no more results to be fetched
   * by setting the `isComplete` flag in the `directorySearchRequest` object.
   *
   * @param {DirectorySearchRequest} request - The search request object.
   */
  const initSearchDirectoryApi = (request: DirectorySearchRequest) => {
    directoryApi.searchDirectory(request).then((data) => {
      const itemCount = (data?.groups?.length ?? 0) + (data?.users?.length ?? 0);
      request.isComplete = itemCount < (2 * DEFAULT_PAGE_SIZE);
      setDirectorySearchRequest(request);
    });
  }

  return (
    <Box sx={styles.container}>
      <TopBar title={t('directory')} hasDivider={!isMobile} />
      <Box sx={styles.subContainer}>
        <Box sx={styles.leftPane}>
          <DirectorySortFilter
            filter={filter}
            deptList={deptApi.state.department.departmentList}
            siteList={siteApi.state.site.siteList}
            onResetFilter={() => onApplyFilter(initialFilter)}
            onApplyFilter={onApplyFilter}
          />
        </Box>
        <Box sx={styles.rightPane}>
          <Box sx={styles.rightPaneHeader}>
            <form onSubmit={onSearchSubmit}>
              <Box sx={styles.searchLayout}>
                <SearchBar
                  sx={styles.searchInput}
                  handleChange={setSearchKey}
                  placeholder={t('search')}
                  endAdornment={
                    isTablet ? (
                      <IconButton sx={styles.filterBtn} onClick={() => setOpenSortFilterDialog(true)}>
                        <FilterIcon />
                      </IconButton>
                    ) : undefined
                  }
                />
                <CustomButton type='submit' startIcon={<SearchIcon />} />
              </Box>
            </form>
            <Grid sx={styles.contentWrapper} ref={containerRef}>
              <Masonry columns={numOfColumns} spacing={isMobile ? 1 : 2} sx={styles.content} sequential>
                {LoginUtil.hasPermission(AppFeature.ManageGroup) &&
                  <CreateGroupCard onClick={() => setOpenCreateGroupDialog(true)} />
                }
                {directoryApi.state.directory.recordList.map((record, index) => (
                  <DirectoryCard
                  key={`directory-record-${index}`}
                  record={record}
                  loggedInUserInfo={loggedInUserInfo}
                  onItemSelected={onItemSelected}
                  />
                ))}
              </Masonry>
            </Grid>
          </Box>
        </Box>
      </Box>
      {(isTablet && openSortFilterDialog) &&
        <DirectorySortFilterDialog
          open={isTablet && openSortFilterDialog}
          filter={filter}
          deptList={deptApi.state.department.departmentList}
          siteList={siteApi.state.site.siteList}
          onResetFilter={() => onApplyFilter(initialFilter)}
          onApplyFilter={onApplyFilter}
          onClose={() => setOpenSortFilterDialog(false)}
        />
      }
      {openCreateGroupDialog &&
        <CreateGroupDialog
          open={openCreateGroupDialog}
          loggedInUserInfo={loggedInUserInfo}
          onClose={() => setOpenCreateGroupDialog(false)}
        />
      }
      <CircularProgressBar show={Util.isApiLoading([SEARCH_DIRECTORY], apiStatus)} />
      <GroupViewPanel
        open={Boolean(openGroupPanel && selectedRecord)}
        group={selectedRecord as Group}
        onClose={handleGroupPanelClose}
      />
      <UserViewPanel
        open={Boolean(openUserPanel && selectedRecord?.email)}
        user={selectedRecord as User}
        onClose={handleUserPanelClose}
      />
    </Box>
  );
};

export default DirectoryScreen;
