import { Field, Form, Formik } from 'formik';

import { Box } from '@mui/material';
import CustomAutocomplete from '../../../../components/CustomAutocomplete';
import CustomInput from '../../../../components/CustomInput';
import React, { } from 'react';
import { User } from '../../../../types/user';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  user: User;
}

/**
 * ProfileTab component for viewing user profile details.
 *
 * @param {Props} props - Component props containing user data.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ProfileTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box sx={styles.wrapper}>
      <Formik enableReinitialize initialValues={props.user} onSubmit={() => {/*Do Nothing*/ }}>
        <Form>
          <Field
            name='about'
            label={t('aboutMe')}
            placeholder={t('aboutYouPlaceholder')}
            component={CustomInput}
            maxRows={Number.MAX_SAFE_INTEGER}
            readOnly
            multiline
          />
          {!Util.isArrayEmpty(props.user.sites) &&
            <Field
              name='sites'
              label={t('sites')}
              placeholder={t('findSites')}
              component={CustomAutocomplete}
              multiple
              menu={[]}
              readOnly
            />
          }
          <Field
            name='departments'
            label={t('departments')}
            placeholder={t('findDepartments')}
            component={CustomAutocomplete}
            multiple
            defaultOptionId={props.user.defaultDepartmentId}
            menu={[]}
            readOnly
          />
        </Form>
      </Formik>
    </Box>
  );
};

export default ProfileTab;