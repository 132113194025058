import {
	apiFailure,
	initPositionListFetch,
	initUserDetailFetch,
	initUserDetailUpdate,
	initUserInvite,
	initUserListFetch,
	initUserListSearch,
	positionListSuccess,
	resetErrorState,
	resetUserState,
	searchUserListSuccess,
	userInviteSuccess,
	userListSuccess
} from './actions';
import { doGet, doPost, doPut } from '../../service';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import BaseResponse from '../../types/base-response';
import { DirectoryRecord } from '../../types/directory-record';
import InviteUserRequest from '../../types/invite-user-request';
import { Pager } from '../../types/pager';
import { PositionListResponse } from '../../types/position-list-response';
import React from 'react';
import { Store } from '../../store/store';
import { User } from '../../types/user';
import { UserDetailResponse } from '../../types/user-detail-response';
import { UserListRequest } from '../../types/user-list-request';
import { UserListResponse } from '../../types/user-list-response';
import { UserUpdateRequest } from '../../types/user-update-request';
import Util from '../../utils/util';
import { interactionHistorySuccess } from '../inbox/actions';
import { searchDirectorySuccess } from '../directory/actions';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for interacting with the user API for managing organization users.
 *
 * @returns {Object} An object containing functions for user API interactions and the current state.
 */
export function useUserApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();

	/**
	 * Fetches the list of available positions from the API.
	 *
	 * @returns {Promise<void>}
	 */
	const fetchPositionList = useCallback(async () => {
		// dispatch(initPositionListFetch());
		try {
			const response: PositionListResponse = await doGet(APIConfig.positionList);
			dispatch(positionListSuccess(response.data ?? []));
		} catch (error: any) { /* eslint-disable-line */
			// dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Sends an invite request to the API.
	 *
	 * @param {InviteUserRequest} request - The invite request object.
	 * 
	 * @returns {Promise<boolean>} A promise that resolves to true if the invitation is successful, false otherwise.
	 */

	const inviteUsers = useCallback(async (request: InviteUserRequest) => {
		dispatch(initUserInvite());
		try {
			const response: BaseResponse = await doPost(APIConfig.inviteUser, request);
			dispatch(userInviteSuccess());

			return response.ok;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Fetches a paginated user list based on the search keyword.
	 *
	 * @param {string} searchKey - Keyword to search for users.
	 * @param {number} page - Page number for pagination (0-based indexing).
	 * 
	 * @returns {Promise<void>}
	 */
	const searchUserList = useCallback(async (searcKey: string, page: number, hideAssignedUser?: boolean) => {
		dispatch(initUserListSearch());
		try {
			let url = APIConfig.searchUsers
				.replace('{search_key}', searcKey)
				.replace('{page}', page.toString());
			if (hideAssignedUser) {
				url = url.concat('&hasPager=false');
			}
			const response: UserListResponse = await doGet(url);
			let userList: Array<User> = state.user.searchedUserList;
			if (!Util.isArrayEmpty(response.data)) {
				if (page === 0) {
					userList = response.data;
				} else {
					userList = [...state.user.userList, ...response.data];
				}
				// Remove duplicates
				userList = Array.from(new Map(userList.map(user => [user.email, user])).values());
				userList.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
			}
			dispatch(searchUserListSuccess(userList));
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Fetches the user list based on the provided request parameters.
	 *
	 * @param {UserListRequest} request User list request object with filtering/sorting options.
	 * 
	 * @returns {Promise<void>}
	 */
	const fetchUserList = useCallback(async (request: UserListRequest) => {
		dispatch(initUserListFetch());
		try {
			const response: UserListResponse = await doPost(APIConfig.userList, request);
			let userList: Array<User> = state.user.userList;
			if (request.page.pageNumber === 0) {
				userList = response.data ?? [];
			} else {
				userList = [...state.user.userList, ...(response.data ?? [])];
			}
			// Remove duplicates
			userList = Array.from(new Map(userList.map(user => [user.email, user])).values());
			dispatch(userListSuccess(userList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.user.userList]);

	/**
	 * Fetches the detailed user profile information for a specific user.
	 *
	 * @param {User} userRequest - User object with email for identification.
	 * 
	 * @returns {Promise<void>}
	 */
	const fetchUserDetail = useCallback(async (userRequest: User) => {
		dispatch(initUserDetailFetch());
		try {
			const url = APIConfig.userDetail.replace('{email_id}', userRequest.email);
			const response: UserDetailResponse = await doGet(url);
			const userList: Array<User> = state.user.userList;
			if (response.data.profile) {
				let user = userList.find(item => item.email === userRequest.email);
				if (!user) {
					userList.push(response.data.profile);
					user = response.data.profile;
				}
				Object.assign(user, {
					...response.data.profile,
					departments: response.data.departmentAndSites?.departments || [],
					sites: response.data.departmentAndSites?.sites || [],
					isCompleted: true,
					defaultDepartmentId: response.data.defaultDepartment.id,
					pager: response.data.pager
				});
			}
			dispatch(userListSuccess(userList));
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.user.userList]);

	/**
	 * Fetches the detailed user profile information for a specific user.
	 *
	 * @param {User} userRequest - User object with email for identification.
	 * 
	 * @returns {Promise<void>}
	 */
	const fetchUserDetailsByEmailId = useCallback(async (emailId: string) => {
		dispatch(initUserDetailFetch());
		try {
			const url = APIConfig.userDetail.replace('{email_id}', emailId);
			const response: UserDetailResponse = await doGet(url);
			const userList: Array<User> = state.user.userList;
			if (response.data.profile) {
				let user = userList.find(item => item.email === emailId);
				if (!user) {
					userList.push(response.data.profile);
					user = response.data.profile;
				}
				Object.assign(user, {
					...response.data.profile,
					departments: response.data.departmentAndSites?.departments || [],
					sites: response.data.departmentAndSites?.sites || [],
					isCompleted: true,
					defaultDepartmentId: response.data.defaultDepartment.id,
				});
			}
			dispatch(userListSuccess(userList));
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.user.userList]);

	/**
	 * Updates the user details in both the user list and directory record.
	 *
	 * @param {User} userRequest - Updated user information.
	 * 
	 * @returns {Promise<UserDetailResponse>} Promise resolving to the API response data.
	 */
	const updateUserDetail = useCallback(async (userRequest: User) => {
		dispatch(initUserDetailUpdate());
		try {
			const { name, email, type, position, about, defaultDepartmentId, departments = [] } = userRequest;
			const updateRequest: UserUpdateRequest = {
				profile: { name, email, type, position, about, defaultDepartmentId },
				loginId: email,
				defaultDepartmentId: defaultDepartmentId,
				departmentIds: departments.map(department => department.id)
			};
			if (userRequest.pager) {
				updateRequest.pager = {
					identifier: userRequest.pager.destination
				}
			}
			const response: UserDetailResponse = await doPut(APIConfig.updateUser, updateRequest);
			const userList: Array<User> = [...state.user.userList];
			if (response.data.profile) {
				const user = userList.find(item => item.email === email);
				if (user) {
					Object.assign(user, {
						...userRequest
					});
				}
			}
			updateDirectory(userRequest);
			dispatch(userListSuccess(userList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Updates the directory record with the updated user information.
	 *
	 * @param {User} user - The updated user information.
	 */
	const updateDirectory = (user: User) => {
		const recordList: Array<DirectoryRecord> = [...state.directory.recordList];
		let record = recordList.find(record => record.email === user.email);
		if (!record) {
			recordList.push(user as DirectoryRecord);
			record = (user as DirectoryRecord);
		} else {
			Object.assign(record, { ...user });
		}
		dispatch(searchDirectorySuccess(recordList));
		updateInteractionHistory(record);
	}

	/**
	 * Updates the interaction history with a new conversation based on the provided directory record.
	 *
	 * @param {DirectoryRecord} record - The directory record representing the new group.
	 */
	const updateInteractionHistory = (record: DirectoryRecord) => {

		const conversationList = state.inbox.messageList ?? [];
		const conversation = conversationList.find(conversation => conversation.recipientLoginId === record.email);
		if (conversation) {
			conversation.recipientName = record.name;
			dispatch(interactionHistorySuccess(conversationList));
		}
	}

	/**
	 * Resets the API error state in the store.
	 */
	const resetError = () => {
		if (state.user.apiStatus?.error) {
			dispatch(resetErrorState());
		}
	}

	/**
	 * Resets the user state in the store.
	 */
	const resetUser = () => {
		dispatch(resetUserState());
	}

	/**
	 * Dispatches an API failure action with an error message.
	 * Handles potential missing error message and uses a default message from translation.
	 *
	 * @param {any} error - The error object (optional).
	 */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
		const message: string = error?.message || t('defaultErrorMsg');
		dispatch(apiFailure(new AppError(error?.code, message)));
	}

	return {
		fetchPositionList,
		inviteUsers,
		searchUserList,
		fetchUserList,
		fetchUserDetail,
		fetchUserDetailsByEmailId,
		updateUserDetail,
		resetError,
		resetUser,
		state
	};
}