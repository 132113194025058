import { ActualSessionStatus, AppFeature, AssignedSessionStatus, InteractionType, TAB_USER_VIEW_DEVICE, TAB_USER_VIEW_PROFILE } from '../../../utils/constants';
import { Avatar, Box, IconButton, Tab, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../../routes/app-nav';
import ChatUtil from '../../../utils/chat-util';
import CircularProgressBar from '../../../components/CircularProgressBar';
import ConversationData from '../../../types/conversation-data';
import CustomButton from '../../../components/CustomButton';
import DeviceTab from './DeviceTab';
import { FETCH_USER_DETAIL } from '../../../data/user/action-types';
import InlineAlert from '../../../components/InlineAlert';
import LoginUtil from '../../../utils/login-util';
import MediaIcon from '../../../components/CustomIcons/MediaIcon';
import MessageIcon from '../../../components/CustomIcons/MessageIcon';
import PencilIcon from '../../../components/CustomIcons/PencilIcon';
import PhoneIcon from '../../../components/CustomIcons/PhoneIcon';
import ProfileTab from './ProfileTab';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SearchIcon from '../../../components/CustomIcons/SearchIcon';
import { USER_VIEW_TAB_LIST } from '../../../utils/ui-constants';
import { User } from '../../../types/user';
import UserEditPanel from '../UserEditPanel';
import Util from '../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

interface Props {
	open: boolean;
	user?: User;
	onClose: () => void;
}

/**
 * Renders a drawer displaying user information, actions, and potentially an edit panel.
 *
 * @param {Props} props - Component props containing open state, navigation mode, user data, and close callback.
 * 
 * @returns JSX element representing the user view panel.
 */
const UserViewPanel: React.FC<Props> = (props: Props) => {

	const { open, user, onClose } = props;
	const styles = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [showUserEdit, setShowUserEdit] = useState<boolean>(false);
	const hasEditPermission = LoginUtil.hasPermission(AppFeature.ManageUser);
	const [selectedTab, setSelectedTab] = React.useState(TAB_USER_VIEW_PROFILE);
	const userApi = useUserApi();
	const apiStatus = userApi.state.user.apiStatus;
	const isChatPage = location.pathname === `${APP_NAV.INBOX}${APP_NAV.INBOX_DETAIL_REL_PATH}`;

	/**
	 * Fetches user details if the provided user object is incomplete.
	 */
	useEffect(() => {
		const userDetail = getUserDetail();
		if (open && user && (!userDetail || !userDetail?.isCompleted)) {
			userApi.fetchUserDetail(user);
		}
	}, [open]);

	/**
	 * Retrieves the department name based on the provided department ID.
	 *
	 * @param {string} deptId - The department ID to look up.
	 * 
	 * @returns {string} The department name, or an empty string if not found.
	 */
	const getDeptNameById = (deptId: string): string => {

		return user?.departments?.find(dept => dept.id === deptId)?.name ?? '';
	}

	/**
	 * Fetches user details if not already available or incomplete.
	 *
	 * @returns {User | null} The fetched user details or null if not found or loading.
	 */
	const getUserDetail = () => {
		const detail = userApi.state.user.userList.find(item => item.email === user?.email);

		return detail;
	}

	const userDetail = getUserDetail();

	/**
	 * Renders the appropriate TabPanel content based on the selected tab ID.
	 *
	 * @param {string} tabId - ID of the selected tab.
	 * 
	 * @returns {JSX.Element} The content for the TabPanel.
	 */
	const getTabPanel = (tabId: string) => {
		if (userDetail) {
			switch (tabId) {
				case TAB_USER_VIEW_PROFILE:
					return <ProfileTab user={userDetail} />;
				case TAB_USER_VIEW_DEVICE:
					return <DeviceTab user={userDetail} />;
			}
		}
	}

	/**
	 * Handles the click event for the message button.
	 *
	 * This function prepares the conversation data and navigates to the message detail page.
	 */
	const onMessageBtnClick = () => {
		if (userDetail) {
			const conversation: ConversationData = {
				...userDetail,
				interactionType: InteractionType.Private,
				senderLoginId: LoginUtil.getLoginId(),
				senderName: LoginUtil.getUserFullName(),
				recipientLoginId: userDetail.email,
				recipientName: userDetail.name,
				recipientActualSessionStatus: ActualSessionStatus.Online,
				recipientAssignedSessionStatus: AssignedSessionStatus.NotDefined,
				count: 0,
				groupRoomKey: '',
				createdAt: '',
				createdByLoginId: '',
				createdByName: ''
			};
			const navPath = isDesktop ? `${APP_NAV.INBOX}${APP_NAV.INBOX_DETAIL_REL_PATH}`
				: APP_NAV.INBOX_DETAIL_REL_PATH.replace('/', '');
			navigate(navPath, {
				state: conversation
			});
		}
	}

	/**
	 * Handles closing the drawer and updating the selected tab.
	 */
	const onDrawerClose = () => {
		setSelectedTab(TAB_USER_VIEW_PROFILE);
		userApi.resetError();
		onClose();
	}

	return (
		<RightDrawerLayout
			hasDefaultBg
			open={open}
			rightIcon={(hasEditPermission && userDetail && userDetail.isCompleted) ? <PencilIcon /> : undefined}
			onActionClick={() => setShowUserEdit(true)}
			onClose={onDrawerClose}
		>
			<InlineAlert message={Util.getApiError([FETCH_USER_DETAIL], apiStatus)} sx={styles.errorLayout} />
			{(open && userDetail && userDetail.isCompleted && !showUserEdit) &&
				<Box sx={styles.contentWrapper}>
					<Box sx={styles.content}>
						<Box sx={styles.groupHeader}>
							<Box sx={styles.avatarWrapper}>
								<Avatar sx={styles.avatar}>
									<Typography variant='h1'>{Util.getInitialsFromName(userDetail.name)}</Typography>
								</Avatar>
							</Box>
							<Box sx={styles.groupContent}>
								<Typography variant='h2' sx={styles.title}>{userDetail.name}</Typography>
								<Typography variant='subheading' sx={styles.subTitle}>{userDetail.email}</Typography>
								<Typography variant='subheading' sx={styles.subTitle}>{getDeptNameById(userDetail.defaultDepartmentId)}</Typography>
								<Typography variant='footer' sx={styles.userType}>{userDetail.type.toUpperCase()}</Typography>
								{(isChatPage || location.pathname === APP_NAV.DIRECTORY) &&
									<Box sx={styles.actionLayout}>
										{!ChatUtil.isLoggedInUser(userDetail.email) &&
											<>
												{/* <IconButton><PhoneIcon /></IconButton> */}
												{!isChatPage &&
													<IconButton onClick={onMessageBtnClick}><MessageIcon /></IconButton>
												}
											</>
										}
										{isChatPage &&
											<>
												<IconButton><MediaIcon /></IconButton>
												<IconButton><SearchIcon /></IconButton>
											</>
										}
									</Box>
								}
							</Box>
						</Box>
						<Box sx={styles.groupInfo}>
							<TabContext value={selectedTab}>
								<TabList variant='fullWidth' onChange={(_, value) => setSelectedTab(value)} sx={styles.tabList}>
									{
										USER_VIEW_TAB_LIST.map((item, index) => (
											<Tab key={`use_view_tab_${index}`} value={item.id} label={item.label} sx={styles.tab} />
										))
									}
								</TabList>
								<Box sx={styles.tabListWrapper}>
									{
										USER_VIEW_TAB_LIST.map((item, index) => (
											<TabPanel key={`user_view_tab_panel_${index}`} value={item.id} sx={styles.tabPanel}>
												{getTabPanel(item.id)}
											</TabPanel>
										))
									}
								</Box>
							</TabContext>
						</Box>
					</Box>
					{/* <Box sx={styles.footer}>
						<CustomButton title={userDetail.locked ? t('unlock') : t('lock')} fullWidth />
					</Box> */}
				</Box>
			}
			{hasEditPermission && userDetail &&
				<UserEditPanel
					open={showUserEdit}
					user={userDetail}
					onClose={() => setShowUserEdit(false)}
				/>
			}
			<CircularProgressBar show={Util.isApiLoading([FETCH_USER_DETAIL], apiStatus)} />
		</RightDrawerLayout>
	);
};

export default UserViewPanel;