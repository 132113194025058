import '@cyntler/react-doc-viewer/dist/index.css';

import { Box, IconButton, Typography } from '@mui/material';
import DocViewer, { DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';

import CrossIcon from '../../../../components/CustomIcons/CrossIcon';
import Dimens from '../../../../theme/dimens';
import MessageData from '../../../../types/message-data';
import React, { } from 'react';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void;
  documents: Array<IDocument>;
  messageData?: MessageData;
  containerHeight: number;
}

/**
 * Renders a layout for displaying document attachments.
 *
 * @param {Props} props - Component props containing `onClose`, `documents`, `messageData`, and `containerHeight`.
 * 
 * @returns {JSX.Element} JSX element representing the AttachmentLayout component.
 */
const AttachmentLayout: React.FC<Props> = (props: Props) => {

  const styles = useStyles(props.containerHeight);
  const { t } = useTranslation();

  /**
   * Generates a subtitle string based on the message data.
   *
   * @param {MessageData} messageData - The message data object.
   * 
   * @returns {string} The subtitle string.
   */
  const getSubTitle = (messageData: MessageData): string => {

    const date = Util.formatUTCtoLocal(messageData.createdAt);

    return `${t('sentBy')} ${messageData.senderName} ${t('on')} ${date}`;
  }

  return (
    <Box sx={styles.attachmentLayout}>
      {props.messageData ? (
          <Box sx={styles.viewheader}>
            <Box sx={styles.titleLayout}>
              <Typography variant='p1' fontWeight={Dimens.fontWeight.medium}>{props.documents[0].fileName}</Typography>
              <Typography variant='footer'>{getSubTitle(props.messageData)}</Typography>
            </Box>
            <IconButton onClick={props.onClose}><CrossIcon /></IconButton>
          </Box>
        ) : (
          <Box sx={styles.editHeader}>
            <IconButton onClick={props.onClose}><CrossIcon /></IconButton>
            <Typography variant='h3' fontWeight={Dimens.fontWeight.bold}>{props.documents[0].fileName}</Typography>
          </Box>
        )
      }
      <DocViewer
        documents={props.documents}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            disableHeader: true
          }
        }}
      />
    </Box>
  );
};

export default AttachmentLayout;
