import {
	apiFailure,
	initCreatePager,
	initPagerListFetch,
	initPagerTypeListFetch,
	initUpdatePager,
	pagerListSuccess,
	pagerTypeListSuccess,
	resetDeviceState,
	resetErrorState
} from './actions';
import { doGet, doPost, doPut } from '../../service';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import { Pager } from '../../types/pager';
import { PagerListRequest } from '../../types/pager-list-request';
import { PagerListResponse } from '../../types/pager-list-response';
import { PagerResponse } from '../../types/pager-response';
import { PagerTypeListResponse } from '../../types/pager-type-list-response';
import React from 'react';
import { Store } from '../../store/store';
import Util from '../../utils/util';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for interacting with the device API for managing organization pager devices.
 *
 * @returns {Object} An object containing functions for device API interactions and the current state.
 */
export function useDeviceApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();

	/**
	 * Fetches a list of pager types from the API.
	 *
	 * This function dispatches an action to initiate the fetch process, makes a GET request to the API endpoint defined in `APIConfig.pagerTypeList`,
	 * and dispatches a success or failure action based on the response.
	 *
	 * @returns {Promise} A promise that resolves when the fetch is complete.
	 */
	const fetchPagerTypeList = useCallback(async () => {
		dispatch(initPagerTypeListFetch());
		try {
			const url = APIConfig.searchUsers;
			const response: PagerTypeListResponse = await doGet(APIConfig.pagerTypeList);
			dispatch(pagerTypeListSuccess(response.data));
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Fetches a list of pagers from the API based on the provided request object.
	 *
	 * This function generates URL parameters based on the request, makes a GET request to the API endpoint defined in `APIConfig.pagerList` with those parameters,
	 * and dispatches a success or failure action based on the response. It handles pagination by replacing the entire list for the first page request (page === 0)
	 * and concatenating subsequent pages while removing duplicates.
	 *
	 * @param {PagerListRequest} request - The request object containing filters and pagination information.
	 * 
	 * @returns {Promise} A promise that resolves with the fetched pager data on success.
	 */
	const fetchPagerList = useCallback(async (request: PagerListRequest) => {
		dispatch(initPagerListFetch());
		try {
			const params = Util.generateUrlParams(request);
			const response: PagerListResponse = await doGet(APIConfig.pagerList + params);
			let pagerList: Array<Pager> = state.device.pagerList;
			if (request.page === 0) {
				pagerList = response.data ?? [];
			} else {
				pagerList = [...state.device.pagerList, ...(response.data ?? [])];
			}
			// Remove duplicates
			pagerList = Array.from(new Map(pagerList.map(pager => [pager.destination, pager])).values());
			dispatch(pagerListSuccess(pagerList));
			
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.user.userList]);

	/**
	 * Creates a new pager in the system.
	 *
	 * This function dispatches an action to initiate the creation process, sends a POST request to the API endpoint defined in `APIConfig.pager` with the provided pager data,
	 * updates the local copy of the pager list with the newly created pager, and dispatches a success or failure action based on the response.
	 *
	 * @param {Pager} request - The pager data to be created.
	 * 
	 * @returns {Promise} A promise that resolves with the newly created pager data on success.
	 */
	const createPager = useCallback(async (request: Pager) => {
		dispatch(initCreatePager());
		try {
			const response: PagerResponse = await doPost(APIConfig.pager, request);
			const pagerList: Array<Pager> = state.device.pagerList;
			response.data.assignedTo = request.assignedTo;
			pagerList.push(response.data);
			dispatch(pagerListSuccess(pagerList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Updates an existing pager in the system.
	 *
	 * This function dispatches an action to initiate the update process, sends a PUT request to the API endpoint defined in `APIConfig.pager` with the updated pager data,
	 * filters out the old pager from the local copy of the list, replaces it with the updated data, and dispatches a success or failure action based on the response.
	 *
	 * @param {Pager} request - The updated pager data.
	 * 
	 * @returns {Promise} A promise that resolves with the updated pager data on success.
	 */
	const updatePager = useCallback(async (request: Pager) => {
		dispatch(initUpdatePager());
		try {
			const response: PagerResponse = await doPut(APIConfig.pager, request);
			const pagerList: Array<Pager> = [...state.device.pagerList.filter(item => item.id !== request.id), request];
			dispatch(pagerListSuccess(pagerList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Resets the API error state in the store.
	 */
	const resetError = () => {
		if (state.device.apiStatus?.error) {
			dispatch(resetErrorState());
		}
	}

	/**
	 * Resets the device state in the store.
	 */
	const resetDevice = () => {
		dispatch(resetDeviceState());
	}

	/**
	 * Dispatches an API failure action with an error message.
	 * Handles potential missing error message and uses a default message from translation.
	 *
	 * @param {any} error - The error object (optional).
	 */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
		const message: string = error?.message || t('defaultErrorMsg');
		dispatch(apiFailure(new AppError(error?.code, message)));
	}

	return {
		fetchPagerTypeList,
		fetchPagerList,
		createPager,
		updatePager,
		resetError,
		resetDevice,
		state
	};
}