import { ActualSessionStatus, SettingsSubMenu, languageList } from '../../../utils/constants';
import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
	SETTINGS_HELP_MENU,
	SETTINGS_LANGUAGE_MENU,
	SETTINGS_MAIN_MENU
} from '../../../utils/ui-constants';

import AppUtil from '../../../utils/app-util';
import CustomButton from '../../../components/CustomButton';
import MenuList from '../SettingsMenuList';
import SettingsMenu from '../../../types/ui/settings_menu_data';
import SettingsMenuData from '../../../types/ui/settings_menu_data';
import theme from '../../../theme/theme';
import { useInboxApi } from '../../../data/inbox/api';
import { useSignInApi } from '../../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Props interface for SettingMenu component.
 *
 * @property {Function} onSettingChange - Callback function triggered when a menu item is clicked.
 */
interface Props {
	onSettingChange: (item: SettingsMenu) => void;
}

/**
 * SettingMenu Component
 *
 * The `SettingMenu` component displays various settings menus including the main menu, language menu,
 * and help menu. It allows users to navigate through different settings and provides an option to log out.
 * On smaller screens, the 'EditProfile' option is hidden from the main settings menu.
 *
 * @component
 * @param {Props} props - The component props containing the callback function to handle menu item selection.
 * @returns {JSX.Element} JSX element representing the settings screen.
 */
const SettingMenu: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const { t } = useTranslation();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [ filteredMainMenu, setFilteredMainMenu ] = useState<Array<SettingsMenuData>>(SETTINGS_MAIN_MENU);
	const languagePreference = AppUtil.getAppLanguage(); // Get the current language preference from app utilities
	const [ languageMenu, setLanguageMenu ] = useState<Array<SettingsMenuData>>(SETTINGS_LANGUAGE_MENU);
	const inboxApi = useInboxApi();
	const signInApi = useSignInApi();

	/**
	 * useEffect Hook
	 * 
	 * This effect runs whenever the screen size (`isSmallScreen`) changes. It calls `updateMainMenu`
	 * to update the menu options accordingly.
	 */
	useEffect(() => {
		updateMainMenu(isSmallScreen);
	}, [ isSmallScreen ]);

	/**
	 * useEffect Hook
	 * 
	 * Runs when the component mounts and whenever `languagePreference` changes.
	 * Updates the language menu options to assign a check icon and update the description
	 * for the currently selected language.
	 * 
	 * @param {string | number} languagePreference - The current language preference ID.
	 */
	useEffect(() => {
		// Find the language object with the matching id
		const language = languageList.find(lang => lang.id === languagePreference);

		const updatedMenuOptions = SETTINGS_LANGUAGE_MENU.map(option => ({
			...option,
			description:
				option.id === SettingsSubMenu.Language
					? language
						? language.value
						: undefined
					: undefined // Set description if it matches the current languagePreference
		}));
		setLanguageMenu(updatedMenuOptions);
	}, [ languagePreference ]);

	/**
	 * updateMainMenu
	 * 
	 * Function to update the main settings menu based on the screen size.
	 * 
	 * @param {boolean} isSmallScreen - A boolean indicating whether the current screen size is small or not.
	 * 
	 * If the screen is small, the 'EditProfile' option is removed from the main settings menu.
	 * If the screen is large, the full main settings menu is displayed.
	 */
	const updateMainMenu = (isSmallScreen: boolean) => {
		if (!isSmallScreen) {
			// Hide the 'EditProfile' option on small screens
			setFilteredMainMenu(
				SETTINGS_MAIN_MENU.filter(item => item.id !== SettingsSubMenu.EditProfile)
			);
		} else {
			// Show the full menu on large screens
			setFilteredMainMenu(SETTINGS_MAIN_MENU);
		}
	};

	/**
	 * handleLogOut
	 *
	 * This function handles the logout functionality. It interacts with the inbox API to update the
	 * session status to 'Offline', performs the sign-out action via the sign-in API, and resets the inbox state.
	 */
	const handleLogOut = () => {
		inboxApi.updateChatSessionStatus(ActualSessionStatus.Offline); // Update chat session status to offline
		signInApi.performSignOut(false); // Perform sign-out action
		inboxApi.resetInbox(); // Reset the global inbox state
	};

	return (
		<Box sx={styles.container}>
			<Box>
				{/* Main Menu */}
				<MenuList menuItems={filteredMainMenu} onItemClick={(menu) => props.onSettingChange(menu)} />

				{/* Language Menu */}
				<Box sx={styles.singleMenu}>
					<MenuList menuItems={languageMenu} onItemClick={(menu) => props.onSettingChange(menu)} />
				</Box>

				{/* Help Menu */}
				<Box sx={styles.singleMenu}>
					<MenuList
						menuItems={SETTINGS_HELP_MENU}
						onItemClick={(menu) => props.onSettingChange(menu)}
					/>
				</Box>
			</Box>
			<CustomButton
				type='submit'
				title={t('signOut')}
				color='secondary'
				fullWidth
				onClick={handleLogOut}
				destructive
			/>
		</Box>
	);
};

export default SettingMenu;
