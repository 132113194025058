import { doDelete, doGet, doPost } from '../../service';
import { domainFailure, domainListSuccess, initDomainAdd, initDomainListFetch, initDomainRemove, resetDomainState, resetErrorState } from './actions';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import DomainListResponse from '../../types/domain-list-response';
import React from 'react';
import { Store } from '../../store/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * React hook for managing domain data through API calls.
 *
 * @returns {{fetchDomainList, addDomain, deleteDomain, state}} An object containing functions for interacting
 * with domain data and the domain state.
 */
export function useDomainApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();

	/**
	 * Fetches the list of domains.
	 * Dispatches actions to indicate fetching and handle success/failure.
	 */
	const fetchDomainList = useCallback(async () => {
		dispatch(initDomainListFetch());
		try {
			const response: DomainListResponse = await doGet(APIConfig.domainList);
			dispatch(domainListSuccess(response.data || []));
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Adds a new domain or updates an existing one.
	 * Dispatches actions to indicate creation/update and handle success/failure.
	 *
	 * @param {Domain} domain - The domain data to create or update.
	 */
	const addDomain = useCallback(async (domain: string) => {
		dispatch(initDomainAdd());
		try {
			await doPost(APIConfig.addDomain, [domain]);
			const domainList = [...state.domain.domainList];
			domainList.unshift(domain);
			dispatch(domainListSuccess(domainList));

			return domain;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.domain.domainList]);

	/**
	 * Removes a domain.
	 * Dispatches actions to indicate deletion and handle success/failure.
	 *
	 * @param {Domain} domain - The domain object containing the ID for deletion.
	 */
	const deleteDomain = useCallback(async (domain: string) => {
		dispatch(initDomainRemove());
		try {
			await doDelete(APIConfig.removeDomain, [domain]);
			const domainList = [...state.domain.domainList.filter(item => item !== domain)];
			dispatch(domainListSuccess(domainList));
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.domain.domainList]);

	/**
	 * Resets the domain error state in the Redux store.
	 */
	const resetError = () => {
		if (state.domain.apiStatus?.error) {
			dispatch(resetErrorState());
		}
	}

	/**
	 * Resets the domain state in the Redux store.
	 */
	const resetDomain = () => {
		dispatch(resetDomainState());
	}

	/**
	 * Dispatches an API failure action with an error message.
	 * Handles potential missing error message and uses a default message from translation.
	 *
	 * @param {any} error - The error object (optional).
	 */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
		const message: string = error?.message || t('defaultErrorMsg');
		dispatch(domainFailure(new AppError(error?.code, message)));
	}

	return {
		fetchDomainList,
		addDomain,
		deleteDomain,
		resetError,
		resetDomain,
		state
	};
}