import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the ProfileScreen component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {
	const theme = useTheme();

	const styles = {
		container: {
			bgcolor: theme.palette.background.paper,
			height: 'inherit'
		}
	};

	return styles;
};

export default useStyles;
