import { ActualSessionStatus, ChatRole, GroupType, InteractionType, MessageActionType } from '../utils/constants';
import { IMessage, StompHeaders, useSubscription } from 'react-stomp-hooks';
import React, { useEffect } from 'react';
import { chatSendSuccess, interactionHistorySuccess } from '../data/inbox/actions';

import APIConfig from '../service/api-config';
import ChatSubscriptionMessage from '../types/chat-subscription-message';
import ChatUtil from '../utils/chat-util';
import ConversationData from '../types/conversation-data';
import { DirectoryRecord } from '../types/directory-record';
import GroupMemberBaseInfo from '../types/group-member-base-info';
import LoginUtil from '../utils/login-util';
import MessageData from '../types/message-data';
import NotificationIcon from '../assets/logo_dark.svg';
import { Store } from '../store/store';
import Util from '../utils/util';
import { searchDirectorySuccess } from '../data/directory/actions';
import { t } from 'i18next';
import { useInboxApi } from '../data/inbox/api';
import { useLocation } from 'react-router-dom';

/**
 * useChatSubscription custom hook
 *
 * Fetches interaction history, manages chat subscriptions, handles incoming messages,
 * and dispatches actions accordingly. Connects to the chat server using STOMP protocol,
 * subscribes to relevant topics, parses incoming messages, and updates the Redux store
 * with new chat messages.
 *
 * @returns {void} - This hook does not return a value, it subscribes to 
 *                   chat events and dispatches actions through the Redux store.
 */
export function useChatSubscription(): void {

  const location = useLocation();
  const { state, dispatch } = React.useContext(Store);
  const inboxApi = useInboxApi();

  /**
   * Manage initial chat set up like setting the session status, fetch intercation history etc.
   * This is called here to avoid multiple server hits.
   */
  useEffect(() => {
    requestPushNotificationPermission();
    manageInitialChatSetUp();
  }, []);

  const requestPushNotificationPermission = () => {
    if ('Notification' in window) {
      if (Notification.permission !== 'denied') {
        // Otherwise, ask the user for permission
        Notification.requestPermission();
      }
    }
  }

  /**
   * Update the chat session status and Fetches interaction history for the inbox list. If there is current active conversation
   * it will also fetch the chat history of that coversation after interaction history API.
   */
  const manageInitialChatSetUp = () => {
    inboxApi.updateChatSessionStatus(ActualSessionStatus.Online);
    inboxApi.fetchInteractionHistory().then(_ => {
      // Check whether there is any selected conversation. If so, call the respective
      // chat history API. This will be called only on page refresh.
      const conversation: ConversationData = location.state;
      if (conversation) {
        const page = 0;
        if (ChatUtil.isPrivateChat(conversation)) {
          inboxApi.fetchPrivateChatHistory(conversation.recipientLoginId ?? '', page);
        } else {
          inboxApi.fetchGroupChatHistory(conversation.groupRoomKey ?? '', page);
        }
      }
    });
  }

  /**
   * Builds STOMP headers including authorization token and login ID.
   * 
   * @returns {StompHeaders} Object containing the STOMP headers.
   */
  const getStompHeaders = (): StompHeaders => {
    const headers: StompHeaders = {
      Authorization: `Bearer ${LoginUtil.getAccessTokenData()?.token}`,
      loginId: LoginUtil.getLoginId()
    }

    return headers;
  }

  /**
   * Constructs subscription endpoints for private and group chats based on the logged-in user ID.
   * 
   * @returns {Array<string>} Array containing subscription topic URLs.
   */
  const getSubscriptionEndpoints = () => {
    const loginId = LoginUtil.getLoginId();
    const deviceId = LoginUtil.getClientId();

    return [
      APIConfig.privateChatSubscription
        .replace('{login_id}', loginId)
        .replace('{device_id}', deviceId),
      APIConfig.groupChatSubscription
        .replace('{login_id}', loginId)
        .replace('{device_id}', deviceId)
    ];
  }

  /**
   * Subscribes/unsubscribes to chat topics based on the `subscribed` state 
   * and handles incoming messages using `onMessageReceived`.
   */
  useSubscription(
    getSubscriptionEndpoints(),
    (message) => onMessageReceived(message),
    getStompHeaders()
  );

  /**
   * Parses the received message, extracts the action type, and dispatches 
   * appropriate actions based on the message content.
   * 
   * @param message {IMessage} Message object received from the STOMP subscription.
   */
  const onMessageReceived = (message: IMessage) => {
    const messageBody: ChatSubscriptionMessage = JSON.parse(message.body);
    console.log(message.body);
    if (messageBody && messageBody.actionType) {
      switch (messageBody.actionType) {
        case MessageActionType.ContentText:
        case MessageActionType.ContentAudio:
        case MessageActionType.ContentPdf:
        case MessageActionType.ContentVideo:
        case MessageActionType.ContentImage:
        case MessageActionType.ForwardText:
        case MessageActionType.ForwardAudio:
        case MessageActionType.ForwardPdf:
        case MessageActionType.ForwardVideo:
        case MessageActionType.ForwardImage:
          onNewChatRecieved(messageBody);
          break;
        case MessageActionType.CreatedGroupRoom:
          onGroupCreated(messageBody.groupRoomResponse);
          break;
        case MessageActionType.UpdatedGroupType:
          onGroupTypeUpdated(messageBody.groupRoomResponse);
          break;
        case MessageActionType.AddedMember:
          onGroupMemberAdded(messageBody.groupName, messageBody.groupType, messageBody.groupMemberResponse);
          break;
        case MessageActionType.RemovedMember:
          onGroupMemberRemoved(messageBody.groupMemberResponse);
          break;
        case MessageActionType.SetRoleMember:
          onGroupRoleChange(messageBody.groupMemberResponse);
          break;
        default:
          break;
      }
    }
  }

  /**
   * Extracts the chat message data from the message body and dispatches a Redux action 
   * (`chatSendSuccess`) to update the store with the new message.
   * 
   * @param messageBody {ChatSubscriptionMessage} Parsed message object containing chat data.
   */
  const onNewChatRecieved = (messageBody: ChatSubscriptionMessage) => {
    const chatMessage: MessageData | undefined = messageBody.privateMessageResponse
      || messageBody.groupMessageResponse;
    if (chatMessage) {
      dispatch(chatSendSuccess(chatMessage));
      const conversation: ConversationData = location.state;
      const isActiveChat = conversation && (chatMessage.groupRoomKey ?
        conversation.groupRoomKey === chatMessage.groupRoomKey
        : conversation.recipientLoginId === chatMessage.senderLoginId);
      if ((!isActiveChat || document.hidden) && !ChatUtil.isLoggedInUser(chatMessage.senderLoginId)) {
        const title = chatMessage.groupRoomKey ? state.inbox.messageList
          .find(item => item.groupRoomKey === chatMessage.groupRoomKey)?.name : chatMessage.senderName;
        const message = chatMessage.attachmentKey ? t('sentAttachment') : chatMessage.contentText;
        const body = chatMessage.groupRoomKey ? `${chatMessage.senderName}: ${message}` : message;
        showNotification(title || '', body);
      }
    }
  }

  /**
   * showNotification function
   * 
   * This function displays a browser notification on the user's screen when the app is in background.
   * 
   * @param title {string} Title of the notification.
   * @param body {string} Body text of the notification.
   * 
   * @returns {void} - This function does not return a value, it displays a notification.
   */
  const showNotification = (title: string, body: string) => {
    const options: NotificationOptions = {
      body: body,
      icon: NotificationIcon,
      dir: 'ltr'
    };
    new Notification(title, options);
  }

  /**
   * Handles successful group creation by updating the conversation list.
   *
   * @param {ConversationData | undefined} groupRoomResponse - The response data from group creation,
   * including conversation details.
   */
  const onGroupCreated = (groupRoomResponse?: ConversationData) => {
    if (groupRoomResponse) {
      groupRoomResponse.interactionType = InteractionType.Group;
      groupRoomResponse.count = 0;
      const conversationList = state.inbox.messageList ?? [];
      conversationList.push(groupRoomResponse);
      dispatch(interactionHistorySuccess(conversationList));
    }
  }

  /**
   * Handles updating the group type of a conversation.
   *
   * If the group room response contains a valid group room key, it finds the corresponding conversation in the inbox message list and updates its group type.
   * Then, it dispatches an action to update the interaction history.
   *
   * @param {ConversationData | undefined} groupRoomResponse The group room response data.
   */
  const onGroupTypeUpdated = (groupRoomResponse?: ConversationData) => {
    if (groupRoomResponse && groupRoomResponse.groupRoomKey) {
      const conversationList = [...state.inbox.messageList ?? []];
      const conversation: ConversationData | undefined = conversationList.find(
        item => item.groupRoomKey === groupRoomResponse.groupRoomKey);
      if (conversation) {
        conversation.groupType = groupRoomResponse.groupType;
        dispatch(interactionHistorySuccess(conversationList));
      }
    }
  }

  /**
   * Handles the addition of a new member to a group chat.
   * 
   * @param {string} groupName - The name of the group the member was added to.
   * @param {GroupMemberBaseInfo} groupMemberResponse - Data about the added member.
   */
  const onGroupMemberAdded = (groupName?: string, groupType?: GroupType,
    groupMemberResponse?: GroupMemberBaseInfo) => {
    if (groupName && groupMemberResponse && groupMemberResponse.groupRoomKey) {
      const conversationList = state.inbox.messageList ?? [];
      let conversation: ConversationData | undefined = conversationList.find(
        item => item.groupRoomKey === groupMemberResponse.groupRoomKey);
      if (conversation) {
        if (!Util.isArrayEmpty(conversation.groupMembers)) {
          const index = conversation.groupMembers?.findIndex(item => item.loginId === groupMemberResponse.loginId);
          if (index === -1) {
            conversation.groupMembers?.push(groupMemberResponse);
          }
        }
      } else {
        conversation = {
          ...groupMemberResponse,
          groupRoomKey: groupMemberResponse.groupRoomKey,
          groupType: groupType,
          name: groupName,
          interactionType: InteractionType.Group,
          count: 0,
          senderLoginId: '',
          senderName: '',
          createdByLoginId: '',
          createdByName: '',
          createdAt: groupMemberResponse.createdAt ?? ''
        }
        conversationList.push(conversation);
      }
      dispatch(interactionHistorySuccess(conversationList));
      const recordList: Array<DirectoryRecord> = [...state.directory.recordList];
      const record = recordList.find(record => record.groupRoomKey === groupMemberResponse.groupRoomKey);
      if (record) {
        if (!Util.isArrayEmpty(record.groupMembers)) {
          const index = record.groupMembers?.findIndex(item => item.loginId === groupMemberResponse.loginId);
          if (index === -1) {
            record.groupMembers?.push(groupMemberResponse);
          }
        }
      }
      dispatch(searchDirectorySuccess(recordList));
    }
  }

  /**
   * Handles the removal of a member from a group chat.
   * 
   * @param {GroupMemberBaseInfo} groupMemberResponse - Data about the removed member.
   */
  const onGroupMemberRemoved = (groupMemberResponse?: GroupMemberBaseInfo) => {
    if (groupMemberResponse && groupMemberResponse.groupRoomKey) {
      let conversationList = state.inbox.messageList ?? [];
      const conversation: ConversationData | undefined = conversationList.find(
        item => item.groupRoomKey === groupMemberResponse.groupRoomKey);
      if (conversation) {
        if (!Util.isArrayEmpty(conversation.groupMembers)) {
          if (ChatUtil.isLoggedInUser(groupMemberResponse.loginId)) {
            conversationList = [...state.inbox.messageList.filter(
              item => item.groupRoomKey !== groupMemberResponse.groupRoomKey
            )];
          } else {
            // eslint-disable-next-line
            conversation.groupMembers = [...conversation.groupMembers!.filter(
              item => item.loginId !== groupMemberResponse.loginId
            )];
          }
          dispatch(interactionHistorySuccess(conversationList));
        }
      }
      const recordList: Array<DirectoryRecord> = [...state.directory.recordList];
      const record = recordList.find(record => record.groupRoomKey === groupMemberResponse.groupRoomKey);
      if (record) {
        if (!Util.isArrayEmpty(record.groupMembers)) {
          // eslint-disable-next-line
          record.groupMembers = [...record.groupMembers!.filter(item => item.loginId !== groupMemberResponse.loginId)];
        }
        if (ChatUtil.isLoggedInUser(groupMemberResponse.loginId)) {
          record.isMember = false;
          record.isAdmin = false;
        }
        dispatch(searchDirectorySuccess(recordList));
      }
    }
  }

  /**
   * Handles a change in a group member's chat role within a group chat.
   * 
   * @param {GroupMemberBaseInfo} groupMemberResponse - Data about the group member whose role has changed (optional).
   */
  const onGroupRoleChange = (groupMemberResponse?: GroupMemberBaseInfo) => {
    if (groupMemberResponse && groupMemberResponse.groupRoomKey) {
      const recordList: Array<DirectoryRecord> = [...state.directory.recordList];
      const record = recordList.find(record => record.groupRoomKey === groupMemberResponse.groupRoomKey);
      if (record) {
        for (const member of record.groupMembers || []) {
          if (member.loginId === groupMemberResponse.loginId) {
            member.chatRole = groupMemberResponse.chatRole;
            break; // Exit the loop when the condition is met
          }
        }
        if (ChatUtil.isLoggedInUser(groupMemberResponse.loginId)) {
          record.isMember = true;
          record.isAdmin = (groupMemberResponse.chatRole === ChatRole.Admin);
        }
        dispatch(searchDirectorySuccess(recordList));
      }
    }
  }

}