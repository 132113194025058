import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';
import { useWindowSize } from '../../../../hooks/use-window-size';

/**
 * useStyles Hook for AttachmentLayout Styles
 * 
 * This hook defines the styles used by the AttachmentLayout component.
 */
const useStyles = (containerHeight: number) => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { section } = Dimens.spacing;

  const styles = {
    attachmentLayout: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 1200,
      bgcolor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      '& #react-doc-viewer': {
        overflowY: 'auto',
        background: theme.palette.background.default,
        bgcolor: theme.palette.background.default,
        '& #proxy-renderer': {
          display: 'flex',
          flex: 1
        },
        '& #image-renderer': {
          backgroundImage: 'none',
          bgcolor: theme.palette.background.default,
          '& img': {
            maxHeight: containerHeight - 100,
            maxWidth: '100%'
          }
        },
        '& #video-renderer': {
          display: 'flex',
          alignItems: 'center',
          '& video': {
            maxHeight: containerHeight - 100,
            maxWidth: '100%'
          }
        },
        '& #pdf-controls': {
          zIndex: 3,
          bgcolor: 'transparent',
          '& #pdf-pagination-info': {
            ...theme.typography.p1,
            color: theme.palette.text.primary
          },
          '& #pdf-download': {
            display: 'none'
          }
        }
      }
    },
    viewheader: {
      ...commonStyles.borderBottom,
      p: section,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& button': {
        p: 0,
        'svg': {
          ...commonStyles.smIcon
        }
      },
      '& h3': {
        ...commonStyles.textEllipsis(1),
        textAlign: 'center',
        flex: 1
      }
    },
    editHeader: {
      p: section,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& button': {
        p: 0
      },
      '& h3': {
        ...commonStyles.textEllipsis(1),
        textAlign: 'center',
        flex: 1
      }
    },
    titleLayout: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '& p': {
        ...commonStyles.textEllipsis(1)
      },
      '& span': {
        ...commonStyles.textEllipsis(1),
        color: theme.palette.text.secondary
      }
    }
  };

  return styles;
};

export default useStyles;